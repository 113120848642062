//Vikailmoitukset sivun pääkomponentti

import React from 'react';
import './vikailmoitukset.style.css';
import VikaIlmoituksetMap from './vikailmoituksetmap.component';

const VikaIlmoituksetPage = ({map}) => {
  return (
    <>
      <VikaIlmoituksetMap map={map}/>
    </>
  );
}

export default VikaIlmoituksetPage;
