import React, {useState, useEffect} from 'react';
import parse from "html-react-parser";
const MarkerSelect = ({kohteet, icons, click}) => {
  const convertString = (juttu) => {return juttu.replaceAll('ä', 'a').replaceAll('ö', 'o').replaceAll('Ä', 'A').replaceAll('Ö', 'O');}
  return (
    <div style={{color:"white", fontSize:"15px", overflowY:"scroll", maxHeight:"200px", display: "flex",
    flexDirection: 'column'}}>
      {kohteet.map((item) => {
          return (
            <div style={{marginBottom: 5,backgroundColor:"rgb(128,186,39)", borderRadius: 5,minWidth: 50,
            maxWidth: 250,padding: 5, textAlign:"left"}}
            onClick={() => {click(item);
            }}
            >
            <img style={{
              //position:"absolute",
              width: "15%",
              left: "0%",
              align:"left",
              marginTop: 0
            }} alt="" src={icons + convertString(item.properties.category) + ".png"} />
          {" "+item.properties.name}
            </div>
          )
        })
      }
    </div>
  )
}

export default MarkerSelect;
