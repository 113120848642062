import react from 'react';
import './ohjepage.css';

const OhjePage = () => {
	return (
		<div className="ohjepage">
				<iframe
					className="ohjepage-iframe"
					src="https://somero.taskussa.info/ohje/index.html#ohje"
					frameborder="0"
					allowFullScreen={true}
				></iframe>
		</div>
	)
};

export default OhjePage;
