import Dexie from 'dexie';

const db = new Dexie("dexiedb");
db.version(2).stores({
    tapahtumat: "++id, tapahtumaid, tapahtumaobjekti",
    paikat: "++id, paikkaid, paikkaobjekti"
})

//TAPAHTUMILLE
/* eslint-disable no-return-await */
const addItemToDb = async(idd, obj) => {
  try{
    await db.tapahtumat.put({
      tapahtumaid: idd,
      tapahtumaobjekti: obj
    })
    console.log(idd + " added to db!!")
    console.log(obj)
  }
  catch(err){
    console.log(err);
  }
}

const removeItemFromDb = async (id) => {
  try{
    await db.tapahtumat.where("tapahtumaid").equals(id).delete();
    console.log(id + " removed from db")
  }catch(err){
    console.log(err)
  }
}

const getItemfromDb = async(id) => {
  let val = false;
  await db.tapahtumat.each(function(item){
      if (item.tapahtumaid == id)
      {val = true;}
  });
  return val;
}

//PAIKOILLE TAI KOHTEILLE
const getPaikkafromDb = async(id) => {
  let val = false;
  await db.paikat.each(function(item){
      if (item.paikkaid == id)
      {val = true;}
  });
  return val;
}

const addPaikkaToDb = async(idd, obj) => {
  try{
    await db.paikat.put({
      paikkaid: idd,
      paikkaobjekti: obj
    })
    console.log(idd + " added to db!!")
    console.log(obj)
  }
  catch(err){
    console.log(err);
  }
}

const removePaikkaFromDb = async (id) => {
  try{
    await db.paikat.where("paikkaid").equals(id).delete();
    console.log(id + " removed from db")
  }catch(err){
    console.log(err)
  }
}

export {db, addItemToDb, getItemfromDb, removeItemFromDb, addPaikkaToDb, getPaikkafromDb, removePaikkaFromDb};
