import React from "react";
import "./switchcheck.styles.css";

const SwitchAll = ({ handleAll, target }) => {
  const [isAll, setIsAll] = React.useState(true);
  return (
    <div
      style={{
        // backgroundColor: "red",
        position: "relative",
        // left: 15,
        top: 0,
        bottom: 0,
        float: "right",
        // marginRight: 10,
        // margin
        overflow: "hidden",
      }}
    >
      <button
        style={{
          width: 50,
          padding: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 20,
          fontSize: 8,
        }}
        className={isAll ? "btn btn-danger" : "btn btn-success"}
        onClick={() => {
          handleAll(target, isAll);
          setIsAll(!isAll);
        }}
      >
        {isAll ? "Kaikki pois" : "Kaikki päälle"}
      </button>
    </div>
  );
};

export default SwitchAll;
