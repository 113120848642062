//Komponentti yläpalkin logolle (salon logo)

import React from 'react';
import './toprightlogo.styles.css';
import Logo from '../../assets/pictures/kulmalogo.png';

const TopRightLogo = () => {
  return (
    <div className="logo-container">
      <div className="stripe"
      style={{
        marginTop: 4,
        position: "absolute",
        right: 43,
        width: 2,
        height: 40,
        backgroundColor: "#101010",
        borderRadius: 50,
        opacity: 0.7
      }}>
      </div>
      <img className="logo" alt="salologo" src={Logo}></img>
    </div>
  )
}

export default TopRightLogo;