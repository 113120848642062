import React, {useState, useEffect} from 'react';
import parse from "html-react-parser";
const PopupContent = ({content}) => {
  const [currContent, setCurrContent] = useState("");
  useEffect(() => {
    var tempContent = content;
    tempContent = tempContent.replaceAll("href=", "target='_blank' href=");//.replaceAll("Kuuntele: ", 'Kuuntele: <iframe width="100%" height="150" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/v-veturitalli/8-suojeluskuntatalo-sininen-talo&color=%2395766f&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=true"></iframe>)')

    if (tempContent.includes("https://soundcloud.com/")){
      var tempArray=tempContent.split("https://soundcloud.com/")[1];
    var soundcloudtrack = tempArray.slice(0, tempArray.indexOf(" "));
    var soundcloudiframe = '<iframe width="100%" height="150" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/' + soundcloudtrack + '&color=%2395766f&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=false&visual=true"></iframe>'
    tempContent = tempContent.replaceAll("https://soundcloud.com/"+soundcloudtrack, soundcloudiframe);
}
  setCurrContent(tempContent);
  }, [content]);
  return (
    <div style={{color:"#101010", fontSize:"18px", overflowY:"scroll", maxHeight:"400px"}}>
      {parse(currContent)}
    </div>
  )
}

export default PopupContent;
