//Karttakomponentti vikailmoitussivulle

import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom"
import AnimatedPopup from 'mapbox-gl-animated-popup';
import 'mapbox-gl/dist/mapbox-gl.css';
import './vikailmoitukset.style.css';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl-csp';
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import VikaIlmoituksetForm from "./vikailmoituksetform.component";
import { AiFillCloseCircle, AiOutlineQuestionCircle } from "react-icons/ai";

const VikaIlmoituksetMap = ({map}) => {
  const [showPopup, setShowPopup] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const icons = process.env.PUBLIC_URL + "/dataa/markers/";
  useEffect(() => {
    var popup = new AnimatedPopup({closeButton:false,closeOnClick: false,maxWidth: "340px",anchor:"top", openingAnimation: {duration: 300,easing: 'easeOutCubic'},closingAnimation: {duration: 300,easing: 'easeOutCubic'}});
    function closeForm()
    {
      popup.remove();setFormOpen(false);map.getSource("markers").setData({"type": "FeatureCollection", "features": []});
    }
    map.addSource("markers", {type: 'geojson',data: {"type": "FeatureCollection", "features": []}});
    map.loadImage(icons + "Marker" + '.png',
    function (error, image) {if (error) {console.log("couldn't load "+"marker icon"); return};
        map.addImage("markers", image);

        map.addLayer({'id': "markers",'type': 'symbol','source': "markers",'layout': {'visibility': 'visible','icon-allow-overlap': true,'icon-ignore-placement': true,'symbol-z-order': "viewport-y",
            'icon-image': "markers",'icon-size': 0.28,'icon-offset': [0, -74]}});
      });
    function OnClick(e)
    {
      if (popup.isOpen())
      {closeForm();return;}


      setFormOpen(true);
      const popupNode = document.createElement("div");
        ReactDOM.render(
          <VikaIlmoituksetForm
          setAddEntry={() => {closeForm();}}
          long={e.lngLat.lng}
          lat={e.lngLat.lat}
        />,
      popupNode);

        popup.setLngLat(e.lngLat).setDOMContent(popupNode).addTo(map);

        map.flyTo({center: e.lngLat, offset: [0, -140],zoom: map.getZoom()<15?15:map.getZoom()});
        var newfeature = {"type": "Feature", "geometry": {"type":"Point", "coordinates":[e.lngLat.lng, e.lngLat.lat]}};
        map.getSource("markers").setData({"type": "FeatureCollection", "features": [newfeature]});
    }
    map.on("click", OnClick);

      return () =>
      {
        if (popup.isOpen())
        popup.remove();
        map.off("click", OnClick);

        if(typeof map.getLayer("markers") !== 'undefined') {
          map.removeLayer("markers");
          map.removeImage("markers");
        }
        map.removeSource("markers");
      };
  }, []);
  return (
    <>
      {showPopup && !formOpen ? (
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            // left: "20%",
            left: 0,
            right: 0,
            width: "min(80%,270px)",
            margin: "auto",
            height: 70,
            bottom: 30,
            backgroundColor: "white",
            boxShadow: "0px 0px 15px 2px black",
            borderRadius: 5,
          }}
        >
          <AiFillCloseCircle
            onClick={() => setShowPopup(false)}
            style={{ position: "absolute", right: 3, top: 3 }}
            color={"grey"}
            size={20}
          />
          <AiOutlineQuestionCircle
            style={{ position: "absolute", left: 3, top: 3 }}
            color={"grey"}
            size={20}
          />
          <h6 style={{ color: "grey", fontSize: "12px", paddingTop: 25 }}>
            Paina kartalta kohtaa, josta haluat tehdä vikailmoituksen
          </h6>
        </div>
      ) : null}
    </>
  );
};

export default VikaIlmoituksetMap;
