import "./App.css";
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import Loadable from "react-loadable";
import "mapbox-gl/dist/mapbox-gl.css";
import axios from "axios";

// eslint-disable-next-line import/no-webpack-loader-syntax
// require('mapbox-gl/dist/mapbox-gl.css');

import MapPageHandler from "./pages/mappage/mappagehandler.component";
import NavbarLeft from "./components/navbar-left/navbar-left.component";
import EmergencyAnnouncement from "./components/emergency-announcement/announcement-container.component";
import LoadingScreen from "./components/loadingscreen/LoadingScreen.component";
import OhjePage from "./pages/ohjepage/ohjepage.component";

const HomePage = Loadable({
  loader: () => import("./pages/homepage/homepage.component"),
  loading() {
    return <LoadingScreen title="Kotisivu" />;
  },
});

const KyselyPage = Loadable({
  loader: () => import("./pages/kyselypage/kyselypage.component"),
  loading() {
    return <LoadingScreen title="Kyselyt" />;
  },
});

const InfoPage = Loadable({
  loader: () => import("./pages/infopage/infopage.component"),
  loading() {
    return <LoadingScreen title="Tietoa sovelluksesta" />;
  },
});

const TiedotteetPage = Loadable({
  loader: () => import("./pages/tiedotteetpage/tiedotteetpage.component"),
  loading() {
    return <LoadingScreen title="Tiedotteet" />;
  },
});
const YhdistyksetPage = Loadable({
  loader: () => import("./pages/yhdistyksetpage/yhdistyksetpage"),
  loading() {
    return <LoadingScreen title="Tiedotteet" />;
  },
});
const LahituottajatPage = Loadable({
  loader: () => import("./pages/lahituottajatpage/yhdistyksetpage"),
  loading() {
    return <LoadingScreen title="Tiedotteet" />;
  },
});

const App = () => {
  const [alertData, setAlertData] = useState("");

  const get_alert = () => {
    axios({
      method: "GET",
      url: "https://kt-cors.azurewebsites.net/tiedotteet.salo.fi/wp-json/wp/v2/posts",
      params: { "news-type": "crisis" },
    })
      .then((response) => response.data)
      .then((data) => {
        if (
          typeof data[0] != "undefined" &&
          data[0].acf["news-type"] === "crisis"
        ) {
          setAlertData(data);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    get_alert();
  }, []);

  const [sidebar, setSidebar] = useState(false);
  const showSidebar = () => setSidebar(!sidebar);

  const handlers = useSwipeable({
    onSwipedRight: (e) => {
      if (e.initial[0] < 25) {
        setSidebar(true);
      }
    },
    onSwipedLeft: (e) => {
      if (sidebar) {
        setSidebar(false);
      }
    },
  });

  //Väliaikainen componentti sivuille
  const Placeholderpage = () => {
    return (
      <div style={{ position: "relative", top: 150, textAlign: "center" }}>
        Page under construction
      </div>
    );
  };

  return (
    <>
      <div className="app" {...handlers}>
        <Router>
          {alertData ? <EmergencyAnnouncement data={alertData} /> : null}
          <NavbarLeft isSidebar={sidebar} toggleSidebar={showSidebar} />
          <div onClick={() => setSidebar(false)}>
            <MapPageHandler />
            <Switch>
              <Route path="/" exact component={HomePage} />
              <Route path="/ajankohtaista" exact component={TiedotteetPage} />
              <Route path="/yhdistykset" exact component={YhdistyksetPage} />
              <Route path="/lahituottajat" exact component={LahituottajatPage} />
              <Route path="/ohje" component={OhjePage} />
              <Route path="/kyselyt" component={KyselyPage} />
              <Route path="/tietoasovelluksesta" component={InfoPage} />
            </Switch>
          </div>
        </Router>
      </div>
    </>
  );
};

export default App;
