//Tapahtumat -sivun alavalikon kortti

import React, {useState} from 'react';
// import InfoContainer from '../infocontainer/infocontainer.component';
import parse from 'html-react-parser';
import DefaultPic from '../../assets/pictures/latausruutukuva.jpg';
import LazyLoad from 'react-lazyload';
import './alavalikko.style.css';

const AlaValikkoCard = ({title, image, date, toggleInfo, id, latlng, move, currentId}) => {

  const [realimage, setRealImage] = useState(image);

  const handle = () => {
    move();
    currentId();
    toggleInfo();
  }

  return (
    <div
    className="alavalikkocard"
    onClick={() => handle()}
    style={{
      // backgroundColor: "red",
      height: "100%",
      minWidth: 163,
      maxWidth: 163,
      // borderRadius: 10,
      // maxWidth: 150,
      height: 200,
      margin: 5,
      padding: 0,
      marginLeft: 10,
      boxShadow: "1px 1px 7px 1px #101010",
    }}>
      
        {/* <div style={{position: 'absolute', height: 45, width: "100%"}}>
          <BsIcons.BsInfoCircle onClick={toggleInfo} size={30} color={"#101010"}style={{cursor: 'pointer', float:"right", marginTop: 7}}/>
        </div> */}
      <LazyLoad>
        <img className="cardimage" src={realimage ? realimage : DefaultPic} style={{minHeight: 80}} />
      </LazyLoad>
      <p
      className="carddate"
      style={{
        textAlign: 'center',
        // backgroundColor: "red",
        // position: 'absolute',
        fontWeight: 600,
        paddingLeft: 5,
        fontSize: 13,
        padding: 5,
        marginTop: 0,

      }}>{`${date.slice(8,10)}.${date.slice(5,7)}.${date.slice(0,4)}`}</p>
      <div className="textcontainer" style={{
        textAlign: 'center',
        overflow: 'scroll',
        height: 75,
        color: "#101010",
        
      }}>
        <h6 
        className="alavalikkocarddata"
        style={{
          fontSize: 12,
          color: "#101010"
        }}>{parse(title)}</h6>

      </div>
      
    </div>
  )
}

export default AlaValikkoCard;