//Hätätiedote komponentti
//Näkyy vain jos message löytyy
import parse from "html-react-parser";
import "./emergency-announcement.styles.css";
const EmergencyAnnouncement = ({data}) => {

  return (
    <>
      <div className="em-container">
        <div className="em-announcement">
        {parse(data[0].title.rendered)}
        </div>
      </div>
    </>
  );
};

export default EmergencyAnnouncement;
