import React from "react";
import SwitchCheck from "./switchcheck";
import SwitchAll from "./switchall";

const SwitchContainer = ({
  name,
  places,
  textWidth,
  float,
  titleLeftMargin,
  toggleBtn,
  handleAll,
}) => {
  return (
    <div>
      <div
        style={{
          float: "left",
          marginLeft: titleLeftMargin,
          marginRight: 10,
          marginBottom: 15,
        }}
      >
        <h6
          className="switchcontainertitle"
          style={{
            fontSize: 14,
            // display: "flex",
            width: 250,
            // backgroundColor: "blue",
            // position: "relative",
            marginBottom: 15,
            // textShadow: "1px 1px 5px black"
          }}
        >
          {name}
          <SwitchAll handleAll={handleAll} target={name} />
        </h6>

        {places.map((item) => {
          return (
            <SwitchCheck
              key={item.title}
              toggleBtn={() => toggleBtn(item.title)}
              show={item.show}
              title={item.title}
              textWidth={textWidth}
              float={float}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SwitchContainer;
