import React, {useState} from 'react';
import * as BsIcons from 'react-icons/bs';

import './map.style.css';

const MapKohdeCard = ({item, title, isLive, setLive, id, description, coords, url, img, toggleInfoOn, moveToPoint, setCurrentInfo, type, selected}) => {

  return (
    <div style={{paddingRight: 10}}>
    <div
    onClick={() => {
      setCurrentInfo(item, type);
      moveToPoint(id, coords);
    }}
    className={selected ? "kohdecard-selected" : "kohdecard"}
    style={{
      // backgroundColor: selected?"rgba(128,186,39, 0.95)":"rgba(128,186,39, 0.475)",
      height: "100%",
      minWidth: 163,
      maxWidth: 163,
      borderRadius: 12,
      margin: "0px 0px 0px 0px",
      padding: 0,
      //boxShadow: "1px 5px 7px black",
    }}>
    {isLive?

    <img
      src={process.env.PUBLIC_URL + "/dataa/markers/Live.png"}
      onClick={() => {
        toggleInfoOn();
        setCurrentInfo(title, url, id, description, type);
        setLive(true);
      }}
      //color={"grey"}
      // color={"white"}
      style={{position:"absolute",width: "40px",cursor: 'pointer', float:"left", marginLeft: 10, marginTop: 10}}
      />:null}
      <BsIcons.BsInfoCircle
        className="kohdettekstit"
        onClick={() => {
          toggleInfoOn();
          setCurrentInfo(title, url, id, description, type);
          setLive(false);
        }}
        size={30}
        //color={"grey"}
        // color={"white"}
        style={{cursor: 'pointer', float:"right", marginRight: 10, marginTop: 7}}/>
      <img
        src={img}
        style={{
          position: 'relative',
          width: "40%",
          left: "30%",
          marginTop: 30
        }}
        />
      <p
        className="kohdettekstit"
        style={{
          // color:"white",
          position: 'relative',
          //top: 5,
          verticalAlign: "middle",
          top: 25-(title.length*0.5),
          height: 60,
          overflow: 'scroll',
          textAlign: 'center',
          //margin: "10px 5px 0px 5px",
          //fontSize: 18-(title.length*0.1),
          textShadow: "0px 0px 10px 1px black",
          fontSize: 14,
          fontWeight: 550
        }}
        >
      {title}
     </p>
    </div>
    </div>
  )
}

export default MapKohdeCard;
