import React from 'react';
import './switchcheck.styles.css';

const SwitchCheck = ({title, show ,textWidth, float, toggleBtn}) => {
  return (
    <div>
      <h6 className="switchchecktext" style={{
        fontSize: 14,
        float: float,
        width: textWidth,
        minHeight: 20
        //textShadow: "1px 1px 5px black",
      }}>{title}</h6><label className="switch">
        <input onChange={(title) => toggleBtn(title)} type="checkbox" checked={show} />
        <span className="slider round"></span>
      </label>

    </div>
    );
}

export default SwitchCheck;
