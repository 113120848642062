//WeatherMonitor attached to TopImage

import './weather.styles.css';
import {useState, useEffect} from 'react';
import {BiUpArrowAlt} from 'react-icons/bi';
import axios from 'axios';

// import logo from "../../public/dataa/saaicons/1.svg";

const WeatherMonitor = () => {

	const icons = process.env.PUBLIC_URL + "/dataa/saaicons/";

	const [currentNum, setCurrentNum] = useState(0);

	const [weatherData, setWeatherData] = useState({
		temperature: 0,
		windspeed: 0,
		windrot: 0,
	});

	const capitalize = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	const cityName = capitalize(process.env.REACT_APP_CITY);

	const JsonData = (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? process.env.PUBLIC_URL + "/dataa/testjsons/Saatiedot.json" : process.env.PUBLIC_URL + "/jsons/Saatiedot.json";

	useEffect(() => {
		axios({
      method: "GET",
      url: JsonData
    })
    .then(res => setWeatherData(res.data))
    .catch(err => console.log(err))
	}, [])

	const [isTemp, setIsTemp] = useState(true);
	const handleChange = () => {
		setIsTemp(!isTemp);
	}

	return (
		<div className="saacontainer" onClick={handleChange}>
			{isTemp
				?	<div className="">
						<p className="temperature">{Math.round(weatherData.temperature)}<p className="celc">°</p></p>
						<img src={icons + weatherData.weathersymbol + ".svg"} className="image2"/>
					</div>
				: <div className="">
						<p className="temperature">{Math.round(weatherData.windspeed)}<p className="windms" style={{paddingLeft: 1}}>m/s</p></p>
						<BiUpArrowAlt size={30} className="imagee" style={{transform: `rotate(${weatherData.winddirection}deg)`}}/>
					</div>
			}
		</div>
	)
};

export default WeatherMonitor;