//Näyttää yläpalkissa sivun nimen

import React from 'react';
import './currentpagename.style.css';

const CurrentPageInfo = (props) => {
  return (
    <div className="containeri" style={{
      zIndex: 500
    }}>
    <h1 className="texti">{props.title}</h1>
    </div>
  );
}

export default CurrentPageInfo;