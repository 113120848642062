import React, { useState, useEffect } from "react";

//Kartan toiminnallisuudet täältä
import MapBox from "../mappage/matkailumap.component";

import RightSideValikko from "../../components/rightsidevalikko/rightsidevalikko.component";
import Dexie from "dexie";

import { useSwipeable } from "react-swipeable";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";

import "./kohteet.style.css";

const KohteetPage = ({ map }) => {
  const db = new Dexie("switchdata2");
  db.version(1).stores({
    dontshow: "++id, name",
  });

  const [kohdePopup, setKohdePopup] = useState(false);

  /* eslint-disable no-return-await */
  const addItemToDb = async (id) => {
    await db.dontshow.add({
      name: id,
    });
  };

  const removeItemFromDb = async (id) => {
    await db.dontshow.where("name").equals(id).delete();
  };

  const getItemfromDb = async (id) => {
    var val = false;
    await db.dontshow.each(function (item) {
      if (item.name == id) {
        val = true;
      }
    });
    return val;
  };

  const [curCoord, setCurCoord] = useState([0, 0]);
  const jsons =
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
      ? process.env.PUBLIC_URL + "/dataa/testjsons/"
      : process.env.PUBLIC_URL + "/jsons/";
  const [isRightValikko, setIsRightValikko] = useState(false);
  const handleChangeValikko = () => setIsRightValikko(!isRightValikko);

  const [switchdata, setSwitchdata] = useState([
    {
      name: "Reitit",
      places: [
        { title: "Luontoreitit", show: true },
        { title: "Matkailureitit", show: true },
      ],
    },
  ]);

  const handleAll = (target, showAll) => {
    if (!showAll) {
      for (let item of switchdata) {
        if (item.name == target)
          for (let place of item.places) {
            place.show = true;
            removeItemFromDb(place.title);
          }
      }
      setSwitchdata([...switchdata]);
    } else {
      for (let item of switchdata) {
        if (item.name == target)
          for (let place of item.places) {
            place.show = false;
            addItemToDb(place.title);
          }
      }
      setSwitchdata([...switchdata]);
    }
  };
  const handlers = useSwipeable({
    onSwipedLeft: (e) => {
      if (!isRightValikko && e.initial[0] > window.innerWidth - 25) {
        setIsRightValikko(true);
      }
    },
    onSwipedRight: (e) => {
      if (isRightValikko) {
        setIsRightValikko(false);
      }
    },
  });

  const handleChange = (id) => {
    for (let item of switchdata) {
      for (let second of item.places) {
        if (second.title === id) {
          console.log(`${second.title} toggled to ${!second.show}`);
          second.show = !second.show;
          if (second.show) {
            removeItemFromDb(id);
          } else {
            addItemToDb(id);
          }
          setSwitchdata([...switchdata]);
        }
      }
    }
  };
  const startChange = async () => {
    for (let item of switchdata) {
      for (let second of item.places) {
        if (await getItemfromDb(second.title)) {
          console.log(`${second.title} toggled to ${!second.show}`);
          second.show = false;
          //setSwitchdata([...switchdata]);
        }
      }
    }
  };
  useEffect(() => {
    getLocation();
    startChange();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude;
        let long = position.coords.longitude;
        setCurCoord([lat, long]);
      });
    }
  };

  return (
    <>
      {/*Add kohde btn*/}
      <div className="addbtn" onClick={() => setKohdePopup(true)}>
        <AiOutlinePlus className="addicon" size={25} />
      </div>

      {kohdePopup ? (
        <div className="kohdepopup">
          <AiOutlineClose
            className="closepopupbtn"
            onClick={() => setKohdePopup(false)}
          />
          <h1 className="kohdepopupheader">Luo uusi kohde!</h1>
          <p className="kohdepopuptext">
            Voit jakaa muille kiinnostavia paikkoja, nähtävyyksiä ja kuvia
          </p>

          <p
            className="luotilibtn"
            onClick={() => {
              window.open(
                `https://admin.taskussa.info/sign-in.php?coord[]=${curCoord[1]}&coord[]=${curCoord[0]}&zoom=12&kaupunki=${process.env.REACT_APP_CITY}`
              );
              setKohdePopup(false);
            }}
          >
            Luo tili
          </p>
          <p
            className="kirjaudubtn"
            onClick={() => {
              getLocation();
              window.open(
                `https://www.admin.taskussa.info/kohteet/index.php?coord[]=${curCoord[1]}&coord[]=${curCoord[0]}&zoom=12&kaupunki=${process.env.REACT_APP_CITY}`
              );
              setKohdePopup(false);
            }}
          >
            Kirjaudu
          </p>
        </div>
      ) : null}
      <div onClick={() => setKohdePopup(false)}>
        {typeof map != "undefined" && switchdata ? (
          <MapBox switchdata={switchdata} map={map} />
        ) : null}
        <RightSideValikko
          handleAll={handleAll}
          handleChangeValikko={handleChangeValikko}
          isValikko={isRightValikko}
          toggleBtn={(id) => handleChange(id)}
          data={switchdata}
          valikkoWidth={270}
          textWidth={160}
          float="left"
          titleLeftMargin={8}
        />
      </div>
    </>
  );
};

export default KohteetPage;
