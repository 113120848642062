//InfoKortti tapahtumille

import React, {useEffect, useState} from 'react';
import * as AiIcons from 'react-icons/ai';
import * as BsIcons from 'react-icons/bs';
import PalauteForm from '../palauteform/palauteform.component';
import './infocontainer.style.css';
import parse from "html-react-parser";
import {addItemToDb, getItemfromDb, removeItemFromDb} from '../dexiedb/dexiedb';
import axios from 'axios';
import SpesificFeedbackForm from './specific-feedback-form';

const InfoContainer = ({data, handleToggle}) => {

  const [liked, setLiked] = useState(true);
  const refreshLiked = async() => {
    if (await getItemfromDb(data.id))
    {
      setLiked(true);
    }
    else {
      setLiked(false);
    }
  };

  const [kyselyData, setKyselyData] = useState({
    Name: "",
    Type: "",
    QuestionText: "",
    Answers: [],
  })

  const checkIfKysely = (id) => {
    //for local testing with proxy
    // const url = "/kysely/kyselyt/database/GetSurvey.php";
    const url = process.env.REACT_APP_DATABASE_URL + "GetSurvey.php"
    // const url = "/kysely/kyselyt/database/GetSurvey.php";
    axios({
      method: "POST",
      url: url,
      data: {"Attached": id, "AttachedType": "event"},
      params: {"City": process.env.REACT_APP_CITY}
    })
    .then(res => {
      console.log(res.data[0].Questions[0]);
      if(res.data.length > 0){
        setKyselyData({
          Name: res.data[0].Name,
          Type: res.data[0].Questions[0].Type,
          QuestionText: res.data[0].Questions[0].QuestionText,
          Answers: res.data[0].Questions[0].Answers,
          QuestionIdd: res.data[0].Questions[0].QuestionID
        })
        setIsKysely(true);
      }
      else{
        setIsKysely(false)
      }
    })
    .catch(err => console.log(err))
  }

  const [isKysely, setIsKysely] = useState(false);
  const handleChange = () => setLiked(!liked);
  const [realImage, setRealImage] = useState(data.image);

  const [showInfo, setShowInfo] = useState(true);
  const [showForm, setShowForm] = useState(false);

  //Laittaa tapahtumakortin palaute formin näkyviin / pois näkyvistä
  const handleFormToggle = () => {
    setShowInfo(!showInfo);
    setShowForm(!showForm);
  };

  useEffect(() => {
    refreshLiked();
    // get_image();
    checkIfKysely(data.id);
  }, [])

  return (
    <>
      <div
        id="containerr"
        style={{
          padding: 10,
          width: "min(90%, 700px)",
          left: 0,
          right: 0,
          margin: "0 auto",
          height: "80%",
          backgroundColor: "white",
          boxShadow: "0px 0px 10px 1px black",
          position: "fixed",
          zIndex: 0,
          top: "15%",
          borderRadius: 10,
          overflow: 'hidden',
          zIndex: 2
        }}
        >
          <div
            style={{
              position: "absolute",
              borderBottom: "1px dashed lightgrey",
              top: 0,
              left: "5%",
              height: 55,
              width: "90%",
              zIndex: -1

            }}
          >

          </div>
        {liked
        ?<AiIcons.AiFillHeart
          onClick={() => {
            handleChange()
            removeItemFromDb(data.id);
          }}
          color={"rgb(150,200,67)"}
          size={35}
          style={{
            position: "absolute",
            cursor: 'pointer'
          }}/>
          :<AiIcons.AiOutlineHeart

          onClick={() => {
            handleChange()
            addItemToDb(data.id, data);
          }}
          size={35}
          color={"black"}
          style={{
            position: "absolute",
            top: 11,
            cursor: 'pointer'
        }}/>
        }

        <BsIcons.BsPencilSquare
          size={32}
          color={showForm ? "#BEBEbf" : "black"}
          style={{
            position: "absolute",
            left: 55,
            top: 12,
            cursor: 'pointer'

          }}
          onClick={() => {
            handleFormToggle();

          }}
        />

        <AiIcons.AiOutlineClose
          color={"black"}
          onClick={handleToggle}
          size={32}
          style={{
            float: "right",
            backgroundColor: "lightgrey",
            borderRadius: 50,
            padding: 3,
            // marginTop: ,
            marginRight: 2,
            cursor: 'pointer'

          }}
          />
        {showInfo
      ?
      <div>
      {data.link.length != ""
        ?
        <div style={{overflow:"hidden", position: "absolute",  top: 50, bottom: 0, fontSize: 20, color: "#101010", right:0, left:0}}>
          <iframe
            seamless="seamless"
            className="iframe"
            scroll="no"
            sandbox="allow-scripts"
            // sandbox="allow-same-origin"
            src={data.link.includes("http") ? data.link.replaceAll("http:", "https:") : "https://" + data.link}
            style={{position: "absolute", height: "100%", border: "none", width: "100%"}}
          />
          </div>
      : <div style={{overflow:"auto", position: "absolute",  top: 50, bottom: 0, fontSize: 20, color: "#101010", right:0, left:0}}>

        <p
          style={{
            position: 'relative',
            verticalAlign: "middle",
            textAlign: 'center',
            width: "100%",
            fontSize: 30,
            fontWeight: 550
          }}
          >
        {data.title}
        </p>

        <img
          src={data.image}
          style={{
            position: 'relative',
            width: "100%",
            top:-10
          }}
          />
        <p
          style={{
            marginLeft:5,
            marginRight:5,
            marginTop:5,
            fontSize: 20,
            fontWeight: 20
          }}
          >
        {data.description}
        </p>
      </div>
    }
    </div>
      : <div>
        {
          //Jos ei ole erillistä kyselyä, näytetään default palauteikkuna, (emojie thingy)
          !isKysely ?  <PalauteForm id={data.id} title={data.title} handleToggle={handleFormToggle}/>
          : <SpesificFeedbackForm handleToggle={handleFormToggle} kyselyData={kyselyData}/>
        }
        </div>
      }
      </div>
    </>
  )
};

export default InfoContainer;
