//Vikailmoitukset Form -komponentti

import React, { useState, useRef } from "react";
import "./vikailmoitukset.style.css";
import selfmark from "../../assets/pictures/markers/blackmarker.png";
import axios from "axios";
import { useEffect } from "react";

const VikaIlmoituksetForm = ({ long, lat, setAddEntry }) => {
  const [aiheet, setAiheet] = useState([
    "Liikuntapaikat",
    "Rakennukset",
    "Puistoalueet",
    "Roskaantumisilmoitus",
    "Kadut",
    "Joku muu",
  ]);
  //Schema lähetettävälle vikailmoitusdatalle
  const [formDataa, setFormData] = useState({
    Subject: "",
    Feedback: "",
    Lat: lat,
    Long: long,
  });

  const [myimg, setMyImg] = useState(null);

  const inputFile = useRef(null);

  const onFileChange = (event) => {
    setMyImg(event.target.files[0]);
  };

  const url = process.env.REACT_APP_DATABASE_URL + "LocationFeedback.php";
  // const url = "http://localhost/imgtest";
  const onButtonClick = () => {
    inputFile.current.click();
  };

  //Lähettää vikailmoitusdatan backendille
  const postData = (data, img) => {
    const formData = new FormData();
    formData.append("Subject", data.Subject);
    formData.append("Feedback", data.Feedback);
    formData.append("Lat", data.Lat);
    formData.append("Lng", data.Long);
    formData.append("Image", img);
    axios({
      method: "POST",
      url: url,
      data: formData,
      params: { City: process.env.REACT_APP_CITY },
    })
      .then((response) => console.log(response))
      .catch((error) => console.log(error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postData(formDataa, myimg);
    setAddEntry();
  };

  useEffect(() => {
    haeAiheet();
  }, []);

  // useEffect(() => {
  //   console.log(formDataa);
  // }, [formDataa]);

  const haeAiheet = async () => {
    axios({
      method: "GET",
      url: "https://admin.taskussa.info/api/report/get_categories.php",
      params: { City: process.env.REACT_APP_CITY },
    })
      .then((res) => (res.data ? setAiheet(res.data) : null))
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ width: 320 }}>
      <div className="vikaheader">
        <img
          title={`${long}, ${lat}`}
          src={selfmark}
          width={40}
          style={{ position: "absolute", right: 10, top: 10 }}
        />
        <h5 style={{ fontSize: 25, padding: 15 }}>Tee vikailmoitus</h5>
      </div>
      <div>
        <div>
          <h6 style={{ marginTop: 60, marginLeft: 10 }}>Valitse Aihe:</h6>
          <div style={{ marginLeft: 10 }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                alignSelf: "center",
                width: "100%",
                // backgroundColor: "red",
                marginLeft: -5,
              }}
              onChange={(e) =>
                setFormData({ ...formDataa, Subject: e.target.id })
              }
            >
              {aiheet.map((item) => {
                return (
                  <div key={item}>
                    <label style={{ width: 155, marginLeft: 0 }}>
                      <input type="radio" id={item} name="aihe" />
                      <span>{item[0].toUpperCase() + item.slice(1)}</span>
                    </label>
                  </div>
                );
              })}
              {/*
              <div>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="liikuntapaikat"
                    name="aihe"
                  />
                  <span>Liikuntapaikat</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="puistoalueet"
                    name="aihe"
                  />
                  <span>Puistoalueet</span>
                </label>
              </div>
              <div>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="kadut"
                    name="aihe"
                  />
                  <span>Kadut</span>
                </label>
              </div>
              <div style={{ position: "absolute", top: 97, left: 150 }}>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="rakennukset"
                    name="aihe"
                  />
                  <span>Rakennukset</span>
                </label>
              </div>
              <div style={{ position: "absolute", top: 125, left: 150 }}>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="roskaaminen"
                    name="aihe"
                  />
                  <span>Roskaantumisilmoitus</span>
                </label>
              </div>
              <div style={{ position: "absolute", top: 152, left: 150 }}>
                <label>
                  <input
                    className="radioin"
                    type="radio"
                    id="jokumuu"
                    name="aihe"
                  />
                  <span>Joku muu</span>
                </label>
              </div>
            */}
            </div>
            <div
              onChange={(e) =>
                setFormData({ ...formDataa, Feedback: e.target.value })
              }
              style={{ marginTop: 5 }}
            >
              <textarea
                maxLength="300"
                style={{
                  margin: "0px auto",
                  border: "1px solid grey",
                  borderRadius: 3,
                  minHeight: 70,
                  maxHeight: 70,
                  minWidth: "96%",
                  maxWidth: "96%",
                  padding: 5,
                  lineHeight: 1,
                }}
                type="text"
                placeholder="Kirjoita palaute"
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ position: "absolute", right: 20 }}>
        {formDataa.Feedback.length + "/" + 300}
      </div>
      {/* Fileinput */}
      <div style={{ textAlign: "center" }}>
        <input
          onChange={onFileChange}
          type="file"
          accept="image/*"
          id="file"
          ref={inputFile}
          style={{ display: "none" }}
        />
        <button
          className="formbtnvika"
          onClick={(e) => onButtonClick(e)}
          style={{
            margin: "5px auto",

            paddingTop: 0,
            paddingBottom: 0,
            height: 23,
            marginTop: 0,
            width: "150px",

            boxShadow: "0px 0px 3px 0.1px black",
          }}
        >
          {myimg ? "Kuva Liitetty" : "Liitä kuva"}
        </button>
      </div>

      <div style={{ textAlign: "center" }}>
        <button
          onClick={(e) => handleSubmit(e)}
          className="formbtnvika"
          style={{
            height: 35,
            marginLeft: 0,
            marginTop: 5,
            width: 150,
            boxShadow: "0px 0px 3px 0.1px black",
          }}
        >
          Lähetä
        </button>
      </div>
    </div>
  );
};

export default VikaIlmoituksetForm;
