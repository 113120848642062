//Oikean puolen "Switch" valikko -komponentti Liikuntapaikoille,
//retkeilykohteille, nähtävyyksille

import React from 'react';
import './rightsidevalikko.style.css';
import SwitchContainer from './switchcontainer';
import {FaGripLinesVertical} from 'react-icons/fa'



const RightSideValikko = ({data, handleChangeValikko, isValikko, valikkoWidth, textWidth, float, titleLeftMargin, toggleBtn, handleAll}) => {

  return (
    <>
    <div
    style={{
      position: 'absolute',
      right: 5,
      top: "50%",
    }}>

      {/* {isValikko
      ? <BsChevronDoubleRight style={{marginRight: valikkoWidth}} onClick={handleChangeValikko} size={30} color={"black"}/>
      : <BsChevronDoubleLeft onClick={handleChangeValikko} size={30} color={"black"}/>
      } */}
      {isValikko
      ? <div
        className="openbtn"
        style={{
          position: "relative",
          right: valikkoWidth -5,
          borderTopLeftRadius: 10,
          borderBottomLeftRadius: 10,
          // backgroundColor: "rgba(250,224,36,0.8)",
          width: 20,
          height: 50}}
          onClick={handleChangeValikko}>

        <FaGripLinesVertical color={"#101010"} size={23} style={{marginTop: 12}}/>
        </div>
      : <div
        className="openbtn"
        style={{
        position: 'relative',
        right: -5,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        // backgroundColor: "rgba(250,224,36,0.8)",
        width: 25,
        height: 50}}
        onClick={handleChangeValikko}>
          <FaGripLinesVertical color={"#101010"} size={23} style={{marginTop: 12}}/>
        </div>
      }
    </div>

    {isValikko
    ?<div className="rightvalikko" style={{
      position: 'absolute',
      right: 0,
      top: 0,
      // backgroundColor: "rgba(250,224,36,0.9)",
      // boxShadow: "10px 0px 30px 45px rgb(13, 104, 139)",
      // backgroundColor: "rgba(0,0,0, 0.8)",
      // boxShadow: "10px 0px 30px 45px rgba(0,0,0, 0.8)",
      height: "100%",
      width: valikkoWidth,
      overflow: 'scroll'

    }}>
      <div style={{
        marginTop: 70
      }}>
        {data.map((item) => {
          return (
            <SwitchContainer
              toggleBtn={(title) => toggleBtn(title)}
              handleAll={handleAll}
              key={item.name}
              name={item.name}
              places={item.places}
              textWidth={textWidth}
              float={float}
              titleLeftMargin={titleLeftMargin}
            />
          )
        })}
      </div>

    </div>
    : null}
    </>
  );
}

export default RightSideValikko;
