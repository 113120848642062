import React, {useState, useEffect} from 'react';
import parse from "html-react-parser";
const LivePopup = ({liveData}) => {

  return (
    <div style={{color:"black", fontSize:"15px", overflowY:"scroll", maxHeight:"200px", display: "flex",
    flexDirection: 'column'}}>

      <p
        style={{
          position: 'relative',
          verticalAlign: "middle",
          textAlign: 'center',

          fontSize: 20,
          fontWeight: 550
        }}
        >
      {liveData.name}
      </p>
      <img
        src={liveData.img}
        style={{
          position: 'relative',
          width: "97%",
        }}
        />
      <p
        style={{
          marginTop:5,
          fontSize: 15,
          fontWeight: 20
        }}
        >
      {liveData.description}
      </p>
    </div>
  )
}

export default LivePopup;
