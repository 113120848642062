import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import MapBox from "./map.component";
import RightSideValikko from "../../components/rightsidevalikko/rightsidevalikko.component";
import Dexie from "dexie";
import { useSwipeable } from "react-swipeable";
import mapboxgl from "mapbox-gl/dist/mapbox-gl-csp";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapboxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";
import "mapbox-gl/dist/mapbox-gl.css";
import "./map.style.css";
import LoadingScreen from "../../components/loadingscreen/LoadingScreen.component";
import KohteetPage from "../kohteetpage/kohteet.component";
import MatkailuPage from "../kohteetpage/matkailu.component";
import MatkailuKohteetPage from "../kohteetpage/matkailukohteet.component";
import KirpputoriPage from "../kohteetpage/kirpparikohteet.component";
import TapahtumatPage from "../tapahtumatpage/tapahtumatpage.component";
import VikaIlmoituksetPage from "../vikailmoituksetpage/vikailmoitukset.component";
const MapPageHandler = () => {
  const [path, setPath] = useState("/");
  const location = useLocation();
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  mapboxgl.workerClass = MapboxWorker;
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mapContainer = useRef();
  const [map, setMap] = useState();
  const geolocate = useRef();
  const mappages = [
    "/kohteet",
    "/matkailu",
    "/tapahtumat",
    "/vikailmoitukset",
    "/matkailukohteet",
    "/Kirpputoripäivä"
  ];
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    if (mappages.includes(path)) {
      if (typeof map != "undefined") {
        setShowMap(true);
        map.setCenter([23.513049766992978, 60.63079270124965]);
        map.setZoom(16);
        geolocate.current.trigger();
      } else {
        var mapperino = new mapboxgl.Map({
          container: mapContainer.current,
          style:
            "mapbox://styles/juhakolu/ckqb44vun1ucp17o7ezqczc67?optimize=true",
          center: [23.513049766992978, 60.63079270124965],
          zoom: 16,
        });
        //Paikanna käyttäjä
        geolocate.current = new mapboxgl.GeolocateControl({
          showAccuracyCircle: false,
          fitBoundsOptions: { zoom: 16 },
          auto: true,
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: true,
        });
        mapperino.addControl(geolocate.current, "top-left");
        geolocate.current.trigger();
        mapperino.on("load", function () {
          setMap(mapperino);
          setShowMap(true);
          geolocate.current.trigger();
        });
      }
    } else {
      setShowMap(false);
    }
  }, [path]);
  useEffect(() => {
    if (typeof map != "undefined") map.resize();
  }, [showMap]);
  return (
    <>
      <div>
        {showMap ? (
          <div>
            <div style={{ height: "100%", width: "100%" }}>
              <div
                style={{ height: "100%", width: "100%" }}
                className="map-container"
                ref={mapContainer}
              ></div>
            </div>
            {path == "/kohteet" ? <KohteetPage map={map} /> : null}
            {path == "/matkailu" ? <MatkailuPage map={map} /> : null}
            {path == "/matkailukohteet" ? <MatkailuKohteetPage map={map} /> : null}
            {path == "/Kirpputoripäivä" ? <KirpputoriPage map={map} /> : null}
            {path == "/tapahtumat" ? <TapahtumatPage map={map} /> : null}
            {path == "/vikailmoitukset" ? (
              <VikaIlmoituksetPage map={map} />
            ) : null}
          </div>
        ) : (
          <div>
            <div>
              <div
                style={{
                  height: "0%",
                  width: "0%",
                }}
                className="map-container"
                ref={mapContainer}
              ></div>
            </div>
            {path == "/kohteet" ? (
              <LoadingScreen title="Paikat ja kohteet" />
            ) : null}
            {path == "/matkailukohteet" ? (
              <LoadingScreen title="Matkailukohteet" />
            ) : null}
            {path == "/Kirpputoripäivä" ? (
              <LoadingScreen title="Kirpputoripäivä" />
            ) : null}
            {path == "/matkailu" ? (
              <LoadingScreen title="Matkailu- ja luontoreitit" />
            ) : null}
            {path == "/tapahtumat" ? (
              <LoadingScreen title="Tapahtumat" />
            ) : null}
            {path == "/vikailmoitukset" ? (
              <LoadingScreen title="Vikailmoitukset" />
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

export default MapPageHandler;
